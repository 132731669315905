import item1 from "../../images/marketplace/items/item1.png";
import item2 from "../../images/marketplace/items/item2.png";
import item3 from "../../images/marketplace/items/item3.png";
import item4 from "../../images/marketplace/items/item4.png";
import item5 from "../../images/marketplace/items/item5.png";
import item6 from "../../images/marketplace/items/item6.png";

export const ProductsData = [
    {
      id: "1",
      supplier_id:"",
      name: "Milwaukee M18 FUEL Taladro Percutor",
      sku:"",
      price: 6599,
      stock: 100,
      description: "Taladro Percutor",
      images: [
      item1
      ],
      category: "Taladro",
      subCategory: "Percutor",
    },
    {
      id: "2",
      supplier_id:"",
      name: "Milwaukee M18 FUEL Atornillador de Impacto",
      sku:"",
      price: 4999,
      stock: 500,
      description: "Atornillador de Impacto	",
      images: [
        item2
      ],
      category: "Atornillador",
      subCategory: "Impacto",
    },
    {
      id: "3",
      supplier_id:"",
      name: "Taladro inalámbrico Pretul",
      sku:"",
      price: 899,
      stock: 200,
      description: "Taladro inalámbrico 3/8', batería ion litio 20 V, Pretul Pretul TALI-20P2",
      images: [
        item3
      ],
      category: "Taladro",
      subCategory: "Inalámbrico",
    },
    {
      id: "4",
      supplier_id:"",
      name: "Taladro inalámbrico DeWalt",
      sku:"",
      price: 3599,
      stock: 50,
      description: "Taladro inalámbrico DeWalt 20V herramienta de 1/2 pulgada solo potente versátil varias aplicaciones",
      images: [
        item4
      ],
      category: "Taladro",
      subCategory: "Inalámbrico",
    },
    {
      id: "5",
      supplier_id:"",
      name: "Esmeriladora Angular MILWAUKEE",
      sku:"",
      price: 6000,
      stock: 75,
      description: "Pule y corta rápidamente los bordes de metal ásperos y pernos oxidados",
      images: [
        item5
      ],
      category: "Esmeriladora",
      subCategory: "Pulidora",
    },
    {
      id: "6",
      supplier_id:"",
      name: "Sopladora de Hojas MILWAUKEE",
      sku:"",
      price: 11000,
      stock: 300,
      description: "Despeje hojas, restos de pasto y desechos de banquetas y patios.",
      images: [
        item6
      ],
      category: "Sopladora",
      subCategory: "Inalámbrica",
    }
  ]
  