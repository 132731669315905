import React, { useState, useEffect } from "react";
import {Row, Col, message} from "antd";
import OrdersTable from "./OrdersTable";
import AgentTable from "../../../components/Generals/OrdersTable/AgentTable";
import LayoutPage from "../../../layouts/LayoutPage";
import SupplierLayout from "../../../layouts/SupplierLayout";
import CPLayout from "../../../layouts/ControlPanelLayout";

// Services
import MarketPlaceService from "../../../utils/api/marketplace";

export default function PurchaseOrders(props) {
	const user = JSON.parse(localStorage.getItem('user'))
  let role = localStorage.getItem("role");

  var Layout = "";
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (
    role == 1 ||
    role == 3 ||
    role == 5 ||
    role == 2 ||
    role == 10
  ) {
    Layout = CPLayout;
  }

  useEffect(() => {
    setLoading(true)
    
    if (role === '1') { // Admin
      MarketPlaceService.getAllOrders().then(resp => {
        setOrders(resp.data)
      }).catch(e => {
        msg('error', 'Error en la base de datos, intentelo más tarde.')
      })

    } else if (role === '4') { // Industry (clients)
      MarketPlaceService.getOrderByClient(user.id).then(resp => {
        setOrders(resp.data.orders)
      }).catch(e => {
        msg('error', 'Error en la base de datos, intentelo más tarde.')
      })
      
    } else if (role === '6') { // Suppliers
      MarketPlaceService.getOrderBySupplier(user.id).then(resp => {
        setOrders(resp.data)
      }).catch(e => {
        msg('error', 'Error en la base de datos, intentelo más tarde.')
      })
    }
    
    setLoading(false);
  }, []);

  // Messages
  const msg = (type, message) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  return (
    <Layout>
      {contextHolder}
      <Row gutter={24}>
        <Col xs={24}>
          {role == 6 || role == 4 ? (
            <OrdersTable openOrders={orders} loading={loading} />
          ) : (
            (role == 1 ||
              role == 5 ||
              role == 3 ||
              role == 2 ||
              role == 10) && (
                <OrdersTable openOrders={orders} loading={loading} />
            )
          )}
        </Col>
      </Row>
    </Layout>
  );
}
