import React from "react";
import { Card, Button, Input } from "antd";
import { useNavigate} from "react-router-dom";

const PurchaseSummaryCard = ({ productsCount, subtotal, freeShipping, checkout }) => {
  const total = subtotal;
  let history = useNavigate();

  const purchaseItems = () => {
    history('/marketplace/checkout');
  }

  return (
    <Card bordered={true} style={{ width: "100%" }}>
      <h3 style={{ marginBottom: "16px" }}>Resumen de compra</h3>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
        <span>Productos ({productsCount})</span>
        <span>${subtotal.toFixed(2)}</span>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
        <span>Envíos ({productsCount})</span>
        <span style={{ color: freeShipping ? "green" : "inherit" }}>
          {freeShipping ? "Gratis" : `$${(subtotal * 0.1).toFixed(2)}`}
        </span>
      </div>
      {/*
      <div style={{ marginBottom: "16px", textAlign: "center" }}>
        <Input
          placeholder="Ingresar código de cupón"
          style={{ textAlign: "center", fontSize: "14px" }}
        />
      </div>
      */}
      
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
          fontWeight: "bold",
          fontSize: "16px",
        }}
      >
        <span>Total</span>
        <span>${total.toFixed(2)}</span>
      </div>
      {!checkout && <Button type="primary" block style={{ backgroundColor: "#007bff", borderColor: "#007bff" }} onClick={() => purchaseItems()}>
        Continuar compra
      </Button>}
    </Card>
  );
};

export default PurchaseSummaryCard;
