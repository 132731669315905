import React from 'react';
import { Row, Typography, Button, Tooltip, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { CategoriesData } from '../../../data/categories/categoriesData';

const { Title } = Typography;

export default function AppBarMarketPlace(props) {
  let role = localStorage.getItem('role');

    let history = useNavigate();

    const categoriesData = CategoriesData;
    
    const shoppingCartClick = () => {
        history('/marketplace/shopping-cart');
    };
    
    const handleClick = (name) => {
      console.log(name);
      const categoryName = name.toLowerCase().replace(/\s+/g, '-');
      history(`/marketplace/${categoryName}`);
    };

    return <>
    <Row gutter={[16, 16]} style={{color: 'white', padding: '14px', backgroundColor: '#001529', margin: 0, display: 'flex', alignItems: 'center'}}>
        <Title level={2} style={{margin: 0, marginRight: 'auto', color: 'white', cursor:'pointer'}}
          onClick={()=>{history('/marketplace')}}>
          EPNO MARKETPLACE
        </Title>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>

          {/* Carrito de compras */}
          
          {role == 4 ?
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Carrito de compras">
            <Button
              shape="circle"
              icon={<ShoppingCartOutlined />}
              style={{ marginBottom: '8px' }}
              onClick={shoppingCartClick}
            />
          </Tooltip>
          <Typography style={{color:'white', fontSize:'12px', marginLeft:'5px', marginBottom:'5px'}}>Carrito</Typography>
          </div>
           : <></>}
          {/* 
          <div style={{ display: 'flex', alignItems: 'center', marginTop:'20px' }}>
            <Input
              placeholder="Search"
              style={{ width: '200px', marginRight: '8px' }}
              allowClear
            />
            <Button type="primary" onClick={() => console.log('Ir')}>
              Ir
            </Button>
          </div>
          */}
        </div>
      </Row>
      <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '10px',marginTop: '3px', padding: 0, width: '100%', backgroundColor: '#ffffff' }}>
      <div style={{display: 'flex', justifyContent: 'space-between', gap: '2px', width:'100%'}}>
        {categoriesData.map((item) => (
          <div
            key={item.key}
            className="category-box"
            onClick={() => handleClick(item.category)} >
            {item.category}
          </div>
        ))}
      </div>
      </Row>
      
    </>
}