import React, { useState } from "react";
import { Form, Input, Radio, Select, Button, Card, Row, Col } from "antd";
import PurchaseSummaryCard from "./PurchaseSummaryCard";

const { Option } = Select;

const PurchaseForm = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const [paymentMethod, setPaymentMethod] = useState(null);

  const handleFinish = (values) => {
    console.log(values);
    if (onSubmit) {
      onSubmit(values);
    }
  };

  return (
    <Row gutter={16} style={{ maxWidth: "1200px", margin: "0 auto" }}>
      {/* Form Section */}
      <Col xs={24} md={16}>
        <Card title="Completar Compra">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            initialValues={{ shippingType: "standard", paymentMethod: "creditCard" }}
          >
            {/* Shipping Address */}
            <Form.Item
              label="Código Postal"
              name="codigoPostal"
              rules={[{ required: true, message: "Por favor ingresa tu código postal" }]}
            >
              <Input placeholder="Código Postal" />
            </Form.Item>

            <Form.Item
              label="Estado"
              name="estado"
              rules={[{ required: true, message: "Por favor ingresa tu estado" }]}
            >
              <Input placeholder="Estado" />
            </Form.Item>

            <Form.Item
              label="Municipio"
              name="municipio"
              rules={[{ required: true, message: "Por favor ingresa tu municipio" }]}
            >
              <Input placeholder="Municipio" />
            </Form.Item>

            <Form.Item
              label="Colonia"
              name="colonia"
              rules={[{ required: true, message: "Por favor ingresa tu colonia" }]}
            >
              <Input placeholder="Colonia" />
            </Form.Item>

            <Form.Item
              label="Calle"
              name="calle"
              rules={[{ required: true, message: "Por favor ingresa tu calle" }]}
            >
              <Input placeholder="Calle" />
            </Form.Item>

            <Form.Item
              label="Número Exterior"
              name="numeroExterior"
              rules={[{ required: true, message: "Por favor ingresa tu número exterior" }]}
            >
              <Input placeholder="Número Exterior" />
            </Form.Item>

            <Form.Item
              label="Número Interior (Opcional)"
              name="numeroInterior"
            >
              <Input placeholder="Número Interior" />
            </Form.Item>

            <Form.Item
              label="Teléfono de Contacto"
              name="telefonoContacto"
              rules={[{ required: true, message: "Por favor ingresa tu teléfono de contacto" }]}
            >
              <Input placeholder="Teléfono de Contacto" />
            </Form.Item>

            {/* Shipping Type */}
            <Form.Item
              label="Tipo de Envío"
              name="shippingType"
              rules={[{ required: true, message: "Por favor selecciona un tipo de envío" }]}
            >
              <Radio.Group>
                <Radio value="standard">Estándar (Gratis)</Radio>
                <Radio value="express">Exprés ($200.00)</Radio>
              </Radio.Group>
            </Form.Item>

            {/* Payment Method */}
            <Form.Item
              label="Método de Pago"
              name="paymentMethod"
              rules={[{ required: true, message: "Por favor selecciona un método de pago" }]}
            >
              <Select
                placeholder="Selecciona un método de pago"
                onChange={(value) => setPaymentMethod(value)}
              >
                <Option value="creditCard">Tarjeta de Crédito/Débito</Option>
                <Option value="paypal">PayPal</Option>
                <Option value="bankTransfer">Transferencia Bancaria</Option>
              </Select>
            </Form.Item>

            {/* Payment Method Details */}
            {paymentMethod === "creditCard" && (
              <>
                <Form.Item
                  label="Número de Tarjeta"
                  name="cardNumber"
                  rules={[{ required: true, message: "Por favor ingresa el número de tu tarjeta" }]}
                >
                  <Input placeholder="Número de Tarjeta" />
                </Form.Item>
                <Form.Item
                  label="Fecha de Expiración"
                  name="cardExpiry"
                  rules={[{ required: true, message: "Por favor ingresa la fecha de expiración" }]}
                >
                  <Input placeholder="MM/AA" />
                </Form.Item>
                <Form.Item
                  label="Código CVV"
                  name="cardCVV"
                  rules={[{ required: true, message: "Por favor ingresa el código CVV" }]}
                >
                  <Input placeholder="CVV" />
                </Form.Item>
              </>
            )}

            {paymentMethod === "paypal" && (
              <Form.Item
                label="Correo Electrónico de PayPal"
                name="paypalEmail"
                rules={[{ required: true, message: "Por favor ingresa tu correo de PayPal" }]}
              >
                <Input placeholder="Correo Electrónico" />
              </Form.Item>
            )}

            {paymentMethod === "bankTransfer" && (
              <Form.Item
                label="Número de Referencia"
                name="bankReference"
                rules={[{ required: true, message: "Por favor ingresa el número de referencia" }]}
              >
                <Input placeholder="Número de Referencia" />
              </Form.Item>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                Generar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>

      {/* Summary Section */}
      <Col xs={24} md={8}>
        <PurchaseSummaryCard productsCount={10} subtotal={10952} freeShippin={true} checkout={true}/>
      </Col>
    </Row>
  );
};

export default PurchaseForm;