import React from 'react';
import { Typography} from 'antd';
import LayoutPage from '../../../layouts/LayoutPage';
import SupplierLayout from '../../../layouts/SupplierLayout';
import CPLayout from '../../../layouts/ControlPanelLayout';
import { ProductsData } from '../../../data/product/productsData';
import '../styles.scss'; // Archivo CSS para los estilos
import ProductList from './ProductList';
import AppBarMarketPlace from '../global_components/AppBar';


export default function SupplierAllProducts(props) {
  let role = localStorage.getItem('role');
  var Layout = "";
  
  const productsData = ProductsData;

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (
    role == 1 ||
    role == 3 ||
    role == 5 ||
    role == 2 ||
    role == 10
  ) {
    Layout = CPLayout;
  }

  return (
    <Layout>
      <AppBarMarketPlace/>
      <Typography style={{fontWeight: 'bold', fontSize:'28px', marginBottom:'20px'}}>Todos los productos</Typography>
      <ProductList products={productsData} catalog={null} category={null} isSupplier={true}/>
    </Layout>
  );
}
