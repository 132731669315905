import React from 'react';
import { Row, Col, Typography, Menu, Breadcrumb, Button, Tooltip, Input } from 'antd';
import LayoutPage from '../../layouts/LayoutPage';
import SupplierLayout from '../../layouts/SupplierLayout';
import CPLayout from '../../layouts/ControlPanelLayout';
import CatBox from '../../images/marketplace/cat-box.png';
import './styles.scss'; // Archivo CSS para los estilos
import { Header } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { CategoriesData } from '../../data/categories/categoriesData';
import galaEPNO from "../../images/marketplace/galaEPNO.png";
import bannerMHC from "../../images/marketplace/bannerMHC2.jpg";
import bannerSpend from "../../images/marketplace/bannerSpend2.png";
import bannerEpno1 from "../../images/marketplace/bannerEpno1.png";
import bannerEpno2 from "../../images/marketplace/bannerEpno1.png";
import AppBarMarketPlace from './global_components/AppBar';

const { Title } = Typography;

export default function MarketplaceHomePage(props) {
  let history = useNavigate();
  let token = localStorage.getItem('token');
  let role = localStorage.getItem('role');

  const categoriesData = CategoriesData;

  var Layout = "";

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (
    role == 1 ||
    role == 3 ||
    role == 5 ||
    role == 2 ||
    role == 10
  ) {
    Layout = CPLayout;
  }

  const banner = [
    { supplier: 'Maypretec', img: galaEPNO, product_id: 'dgsyuadg128g21' },
    { supplier: 'Maypretec', img: bannerMHC, product_id: 'dgsyuadg128g21' },
    { supplier: 'Maypretec', img: bannerEpno1, product_id: 'dgsyuadg128g21' },
    { supplier: 'Maypretec', img: bannerEpno2, product_id: 'dgsyuadg128g21' },
    { supplier: 'Maypretec', img: bannerSpend, product_id: 'dgsyuadg128g21' }
  ];  

  const handleClick = (name) => {
    console.log(name);
    const categoryName = name.toLowerCase().replace(/\s+/g, '-');
    history(`/marketplace/${categoryName}`);
  };

  return (
    <Layout>
      <AppBarMarketPlace/>
      <div className="demo-logo" />
      <Row gutter={[16, 16]} style={{ marginBottom: '24px', display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', 
        gridTemplateRows: 'auto',
        height: '400px' }}>
      {banner.map((item, index) => (
        <Col
          key={index}
          style={index === 0 ? { gridColumn: 'span 2', gridRow: 'span 2' } : {}}
        >
          <img
            src={item.img}
            alt={`Banner Section ${index + 1}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Col>
      ))}
    </Row>

    <Row gutter={[16, 16]}>
  {categoriesData.map((category, index) => (
    <Col
      key={index}
      xs={24}
      sm={12}
      md={8}
      className="category-col"
      onClick={() => handleClick(category.category)} // Evento de clic en la columna
    >
      <div className="category-content">
        <div className="image-container">
          <img
            src={category.img}
            alt={category.category}
            className="category-image"
          />
        </div>
        <div className="category-footer">
          <Title level={4} className="category-title">
            {category.category}
          </Title>
          {category.description && <p className="category-description">{category.description}</p>}
        </div>
      </div>
    </Col>
  ))}
</Row>

    </Layout>
  );
}
