import empaque from "../../images/marketplace/EMPAQUE.png";
import pallets from "../../images/marketplace/pallets.jpeg";
import carton from "../../images/marketplace/carton.png";
import fleje from "../../images/marketplace/fleje.png";
import esquineros from "../../images/marketplace/esquinero.png";
import relleno from "../../images/marketplace/relleno.jpg";
import rh from "../../images/marketplace/RH.png";
import bolsa from "../../images/marketplace/bolsa de regalo.png";
import papeleria from "../../images/marketplace/papeleria.png";
import uniforme from "../../images/marketplace/uniforme.jpg";
import gafete from "../../images/marketplace/gafete.jpeg";
import imprenta from "../../images/marketplace/imprenta.jpeg";
import botiquin from "../../images/marketplace/botiquin.png";
import sumElec from "../../images/marketplace/SE.png";
import cableado from "../../images/marketplace/cableado.jpeg";
import boton from "../../images/marketplace/botoneria.jpg";
import andon from "../../images/marketplace/andon.png";
import switches from "../../images/marketplace/switch.jpeg";
import fusible from "../../images/marketplace/fusible.png";
import actuador from "../../images/marketplace/actuador.jpg";
import tablilla from "../../images/marketplace/tablilla.jpg";
import plc from "../../images/marketplace/plc.jpeg";
import toolcrib from "../../images/marketplace/TOOLCRIB.png";
import herrMec from "../../images/marketplace/herramienta mecanica.jpg";
import ehs from "../../images/marketplace/ehs.jpg";
import herrEle from "../../images/marketplace/herramienta electrica.jpg";
import tornillo from "../../images/marketplace/tornillos y tuercas.webp";
import ferreteria from "../../images/marketplace/ferreteria.jpeg";
import limpieza from "../../images/marketplace/limpieza.jpg";
import technology from "../../images/marketplace/SISTEMAS.png";
import computo from "../../images/marketplace/equipo de computo.png";
import impresora from "../../images/marketplace/impresora.jpeg";
import servidor from "../../images/marketplace/servidores.jpeg";
import periferico from "../../images/marketplace/perifericos.jpg";
import redes from "../../images/marketplace/redes.jpg";

export const CategoriesData = [
    {
    key:1,
    category:"Empaque",
    description:"",
    img: empaque,
    subCategories: [{
        name:"Pallets",
        img:pallets,},
        {
        name:"Cartón",
        img:carton},
        {
        name:"Fleje",
        img:fleje},
        {
        name:"Esquineros",
        img:esquineros},
        {
        name:"Relleno",
        img:relleno,},
    ]
    },
    {
    key:2,
    category:"Recursos Humanos",
    description:"",
    img:rh,
    subCategories: [{
        name:"Regalos",
        img:bolsa,},
        {
        name:"Papelería",
        img:papeleria},
        {
        name:"Uniformes",
        img:uniforme},
        {
        name:"Gafetes",
        img:gafete},
        {
        name:"Imprenta",
        img:imprenta,
        },{
        name:"Botiquín",
        img:botiquin,},
    ]
    },
    {
    key:3,
    category:"Suministros Electrónicos",
    description:"",
    img:sumElec,
    subCategories: [{
        name:"Cableado",
        img:cableado,},
        {
        name:"Botonería",
        img:boton},
        {
        name:"Andon",
        img:andon},
        {
        name:"Switches",
        img:switches},
        {
        name:"Fusibles",
        img:fusible},
        {
        name:"Actuadores",
        img:actuador,},
        {
        name:"Tablillas",
        img:tablilla,},
        {
        name:"PLC's",
        img:plc,}
    ]
    },
    {
    key:4,
    category:"Tool Crib",
    description:"",
    img:toolcrib,
    subCategories: [{
        name:"EHS",
        img:ehs,},
        {
        name:"Herramienta Mecánica",
        img:herrMec},
        {
        name:"Herramienta Eléctrica",
        img:herrEle},
        {
        name:"Tornillos Y Tuercas",
        img:tornillo},
        {
        name:"Ferretería",
        img:ferreteria,},
        {
        name:"Limpieza",
        img:limpieza,},
    ]
    },
    {
        key:5,
        category:"Tecnología",
        description:"",
        img:technology,
        subCategories: [{
            name:"Equipo De Cómputo",
            img:computo,},
            {
            name:"Impresoras",
            img:impresora},
            {
            name:"Servidores",
            img:servidor},
            {
            name:"Periféricos",
            img:periferico},
            {
            name:"Redes",
            img:redes,},
        ]
        },
];