import React from 'react';
import {useParams} from "react-router-dom";
import {Typography, Breadcrumb} from 'antd';
import LayoutPage from '../../layouts/LayoutPage';
import SupplierLayout from '../../layouts/SupplierLayout';
import CPLayout from '../../layouts/ControlPanelLayout';
import { ProductsData } from '../../data/product/productsData';
import './styles.scss'; // Archivo CSS para los estilos
import { useNavigate } from 'react-router-dom';
import ProductList from './global_components/ProductsList';
import AppBarMarketPlace from './global_components/AppBar';

const { Title } = Typography;

export default function Catalog(props) {
  let history = useNavigate();
  const { category, catalog } = useParams();
  let role = localStorage.getItem('role');
  var Layout = "";
  
  const productsData = ProductsData;

  const formatCategoryName = (categoryName) => {
    return categoryName
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  const formatCatalog = (catalogName) => {
    return catalogName
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formattedCategory = formatCategoryName(category);
  const formattedCatalog = formatCatalog(catalog);

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (
    role == 1 ||
    role == 3 ||
    role == 5 ||
    role == 2 ||
    role == 10
  ) {
    Layout = CPLayout;
  }

  const handleBreadcrumbClick = (link) => {
    history(link);
  };

  return (
    <Layout>
      <AppBarMarketPlace/>
      <div style={{marginBotton:'40px'}}>
      <Breadcrumb style={{marginBottom:'20px'}}>
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick('/marketplace')}>
          Inicio
        </Breadcrumb.Item>
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick(`/marketplace/${category}`)}>{formattedCategory}</Breadcrumb.Item>
        <Breadcrumb.Item className='breadcrumb-item'>{formattedCatalog}</Breadcrumb.Item>
      </Breadcrumb>
    </div>

      <Title style={{fontWeight: 'bold', fontSize:'28px', marginBottom:'20px'}}>{formattedCatalog} - todos los productos</Title>
      <ProductList products={productsData} catalog={formattedCatalog} category={formattedCategory}/>
    </Layout>
  );
}
