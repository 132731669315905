import React, { useState, useEffect } from 'react';
import { Row, Typography, Breadcrumb} from 'antd';
import LayoutPage from '../../../layouts/LayoutPage';
import SupplierLayout from '../../../layouts/SupplierLayout';
import CPLayout from '../../../layouts/ControlPanelLayout';
import '../styles.scss'; // Archivo CSS para los estilos
import { useNavigate } from 'react-router-dom';
import ShoppingList from './ShoppingList';
import AppBarMarketPlace from './AppBar';

// Services
import MarketPlaceService from '../../../utils/api/marketplace';

export default function ShoppingCart(props) {
  const user = JSON.parse(localStorage.getItem('user'))
  let role = localStorage.getItem('role');
  
  let history = useNavigate();
  var Layout = "";
  
  const [cart, setCart] = useState([]);
  const [products, setProducts] = useState([]);
  const [qty, setQty] = useState(1);

  //Fetch cart products
  useEffect(() => {
    MarketPlaceService.getCartByClient(user.id)
      .then((resp) => {
        console.log('Cart response:', resp);
        
        if (resp.data) {
          // Actualizar el carrito y los productos
          setCart(resp.data);
          setProducts(resp.data.products || []);
        } else {
          console.warn('No cart data found');
          setCart([]);
          setProducts([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching cart:', error);
        setCart([]);
        setProducts([]);
      });
  
  }, [qty]); // Dependencia de qty
  
  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (
    role == 1 ||
    role == 3 ||
    role == 5 ||
    role == 2 ||
    role == 10
  ) {
    Layout = CPLayout;
  }

  const handleBreadcrumbClick = (link) => {
    history(link);
  };

  return (
    <Layout>
      <AppBarMarketPlace/>

      <div style={{marginBotton:'40px'}}>
      <Breadcrumb style={{marginBottom:'20px'}}>
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick('/marketplace')}>
          Inicio
        </Breadcrumb.Item>
        <Breadcrumb.Item className='breadcrumb-item'>Carrito de compras</Breadcrumb.Item>
      </Breadcrumb>
    </div>

      <Typography style={{fontWeight: 'bold', fontSize:'28px'}}>Carrito de compras</Typography>
      <ShoppingList
        cart={cart}
        cartProducts={products}
        catalog={'catalogo'}
        category={'categoria'}
        qty={qty}
        setQty={setQty}
      />
    </Layout>
  );
}
