import React from 'react';
import {useParams} from "react-router-dom";
import { Row, Typography, Breadcrumb} from 'antd';
import LayoutPage from '../../../layouts/LayoutPage';
import SupplierLayout from '../../../layouts/SupplierLayout';
import CPLayout from '../../../layouts/ControlPanelLayout';
import { ProductsData } from '../../../data/product/productsData';
import '../styles.scss'; // Archivo CSS para los estilos
import { useNavigate } from 'react-router-dom';
import { CategoriesData } from '../../../data/categories/categoriesData';
import PurchaseForm from '../global_components/PurchaseForm';
import AppBarMarketPlace from '../global_components/AppBar';

const { Title } = Typography;

export default function CheckoutPage(props) {
  let history = useNavigate();
  let token = localStorage.getItem('token');
  let role = localStorage.getItem('role');
  var Layout = "";
  
  const productsData = ProductsData;
  const categoriesData = CategoriesData;

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (
    role == 1 ||
    role == 3 ||
    role == 5 ||
    role == 2 ||
    role == 10
  ) {
    Layout = CPLayout;
  }

  const handleClick = (option ,productName) => {
    switch (option) {
      case "category":
        history(`/marketplace/${productName}`);
        break;
      case "product":
        const formattedProduct = productName.toLowerCase().replace(/\s+/g, '-');
        history(`/marketplace/category/${formattedProduct}`);
        break;
    }
  };  

  const handleBreadcrumbClick = (link) => {
    history(link);
  };

  return (
    <Layout>
      <AppBarMarketPlace/>
     <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '2px', marginTop: '3px', padding: 0, width: '100%', backgroundColor: '#ffffff' }}>
      <div style={{display: 'flex', justifyContent: 'space-between', gap: '2px', width:'100%'}}>
        {categoriesData.map((item) => (
          <div
            key={item.key}
            className="category-box"
            onClick={() => handleClick("category",'item.category')} >
            {item.category}
          </div>
        ))}
      </div>
      </Row>

      <div style={{marginBotton:'40px'}}>
      <Breadcrumb style={{marginBottom:'20px'}}>
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick('/marketplace')}>
          Inicio
        </Breadcrumb.Item>
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick('/marketplace/shopping-cart')}>Carrito de compras</Breadcrumb.Item>
        <Breadcrumb.Item className='breadcrumb-item'>Pago</Breadcrumb.Item>
      </Breadcrumb>
    </div>

      <Typography style={{fontWeight: 'bold', fontSize:'28px'}}>Genera tu orden de compra</Typography>
      <PurchaseForm/>
    </Layout>
  );
}
