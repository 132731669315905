import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import { Row, Typography,Breadcrumb} from 'antd';
import LayoutPage from '../../../layouts/LayoutPage';
import SupplierLayout from '../../../layouts/SupplierLayout';
import CPLayout from '../../../layouts/ControlPanelLayout';
import { ProductsData } from '../../../data/product/productsData';
import '../styles.scss';
import { useNavigate } from 'react-router-dom';
import { CategoriesData } from '../../../data/categories/categoriesData';
import ProductList from './ProductList';
import AppBarMarketPlace from '../global_components/AppBar';
import MarketPlaceService from '../../../utils/api/marketplace';
import UserService from '../../../utils/api/users';

const { Title } = Typography;

export default function AllProducts(props) {
  let history = useNavigate();
  const { supplier } = useParams();
  console.log(supplier)
  let token = localStorage.getItem('token');
  let role = localStorage.getItem('role');
  var Layout = "";

  const [supplierData, setSupplierData] = useState([])
  
  const productsData = ProductsData;

  const formatSupplier = (catalogName) => {
    return catalogName
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const categoriesData = CategoriesData;
  const formattedSupplier = formatSupplier(supplier);

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (
    role == 1 ||
    role == 3 ||
    role == 5 ||
    role == 2 ||
    role == 10
  ) {
    Layout = CPLayout;
  }

  const handleClick = (option ,productName) => {
    switch (option) {
      case "category":
        history(`/marketplace/${productName}`);
        break;
      case "product":
        const formattedProduct = productName.toLowerCase().replace(/\s+/g, '-');
        history(`/marketplace/categoria/${formattedProduct}`);
        break;
    }
  };  

  const handleBreadcrumbClick = (link) => {
    history(link);
  };

  useEffect(() => {
    console.log(supplier)
    UserService.GetUserById({id: supplier}).then(resp => {
      console.log(resp)
      setSupplierData(resp.data)
    })
  
    return () => {
      
    }
  }, [])
  

  return (
    <Layout>
      <AppBarMarketPlace/>
      <div style={{marginBotton:'40px'}}>
      <Breadcrumb style={{marginBottom:'20px'}}>
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick('/marketplace')}>
          Inicio
        </Breadcrumb.Item>
        {/* 
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick(`/marketplace/suppliers`)}>Proveedores</Breadcrumb.Item>
        */}
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick(`/marketplace/suppliers/${supplier}`)}>{supplierData.bussiness}</Breadcrumb.Item>
        <Breadcrumb.Item className='breadcrumb-item'>Todos los productos</Breadcrumb.Item>
      </Breadcrumb>
    </div>
      <Typography style={{fontWeight: 'bold', fontSize:'28px'}}>{supplierData.bussiness} - todos los productos</Typography>
      {/*
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{fontSize:'16px', marginRight:'10px'}}>Buscar: </Typography>
          <Input
            placeholder="..."
            style={{ width: '300px' }}
            allowClear
          />
        </div>
        */}
      <ProductList products={productsData} catalog={supplier} category={'Categoria'}/>
    </Layout>
  );
}
