import React, { useState, useEffect } from "react";
import { Row, Col, Input, Table, Button, Tag, Avatar } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import ServiceStatus from "../../../components/Generals/ServiceStatus";

export default function OrdersTable(props) {
	const { openOrders, loading } = props;
	let history = useNavigate();
	let role = localStorage.getItem("role");
	console.log(props)
	console.log(openOrders)

	const location = useLocation();
	const isInMarketplace = location.pathname === "/marketplace/orders";

	const [tableData, setTableData] = useState(
		openOrders.map((order, index) => {
			return {
				key: index,
				order_id: order.id ? order.id : order.orderId,
				order_products: order.products,
				client_name: order.userName ? order.userName : order.client_name,
				order_status: order.status,
				days_of_age: moment(order.createdAt).fromNow(), 
			};
		})
	);
	const [isDataFiltered, setIsDataFiltered] = useState(false);
	const [searchedValue, setSearchedValue] = useState();

	useEffect(() => {
		if (isDataFiltered)
			setTableData(
				openOrders
					.filter((order) =>
						order.order_num
							.toLowerCase()
							.includes(searchedValue.toLowerCase())
					)
					.map((order, index) => {
						return {
							key: index,
							order_id: order.id ? order.id : order.orderId,
							order_products: order.products,
							client_name: order.userName ? order.userName : order.client_name,
							order_status: [order.service.step_id],
							new: order.id ? false : true,
							days_of_age: moment(order.created_at).fromNow(),
						};
					})
			);
		else
			setTableData(
				openOrders.map((order, index) => {
					return {
						key: index,
						order_id: order.id ? order.id : order.orderId,
						order_products: order.products,
						client_name: order.userName ? order.userName : order.client_name,
						order_status: order.status,
						days_of_age: moment(order.createdAt).fromNow(), 
					};
				})
			);
	}, [searchedValue, openOrders, isDataFiltered]);

	const { Search } = Input;

	const handleSearch = (searchedText) => {
		setIsDataFiltered(true);
		setSearchedValue(searchedText);
	};

	const handleReset = () => {
		setIsDataFiltered(false);
		setSearchedValue(null);
	};

	const handleNav = (route) => {
    history(route)
  }

	const columns = [
		{
      title: "# Orden",
      dataIndex: "order_id",
      key: "order_id",
      filtered: isDataFiltered,
      align: "center",
      onFilter: (value, record) => record.order_id.toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => a.order_id.length - b.order_id.length,
      render: (text, record) => (
        <Link to={`details/${record.order_id}`}>{text}</Link>
      ),
    },
		{
			title: "Productos",
			dataIndex: "order_products",
			key: "order_products",
			sorter: (a, b) => a.order_products.length - b.order_products.length,
			render: (products) => (
				<Avatar.Group shape="square">
					{products.map((product) => (
						<Avatar
							key={product.id}
							src={product.imgs[0]}
							alt={product.name}
							style={{ margin: "0 4px" }}
						/>
					))}
				</Avatar.Group>
			),
			sortDirections: ["descend", "ascend"],
		},
		
		{
			title: "Cliente",
			dataIndex: "client_name",
			key: "client_name",
			align: "center",
			sorter: (a, b) => a.client_name.length - b.client_name.length,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Status",
			dataIndex: "order_status",
			key: "order_status",
			align: "center",
			render: (status) => <ServiceStatus status={status}  />,
			sorter: (a, b) => (a.order_status - b.order_status),
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Antiguedad",
			dataIndex: "days_of_age",
			key: "days_of_age",
			align: "center",
			sorter: (a, b) => new Date(a.days_of_age) - new Date(b.days_of_age),
			sortDirections: ["descend", "ascend"],
		},
	];
	return (
		<>
			<Row gutter={[12, 12]}>
				<Col xs={20}>
					<Search
						placeholder="Filtrar por numero de orden"
						allowClear
						enterButton="Buscar"
						size="large"
						onSearch={handleSearch}
					/>
				</Col>
			</Row>
			<Row gutter={[12, 12]}>
				<Col xs={24}>
					<Table
						columns={columns}
						dataSource={tableData}
						scroll={{ x: "100vh" }}
						loading={loading}
					/>
				</Col>
			</Row>
		</>
	);
}
