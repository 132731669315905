import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Divider, Button, Card, List, Typography, Row, Col, Tag, Collapse, Avatar } from 'antd';
import { CheckCircleOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import LayoutPage from '../../../layouts/LayoutPage';
import SupplierLayout from "../../../layouts/SupplierLayout";
import CPLayout from "../../../layouts/ControlPanelLayout";
import OrderInfo from '../../../components/OrderInfo';
import OrderUsers from '../../../components/Generals/OrderUsers/OrderUsers';
import StepsGeneral from '../../../components/StepsLogsGeneral';

// Services
import MarketPlaceService from '../../../utils/api/marketplace';
import UserService from '../../../utils/api/users';
const { Title, Text } = Typography;
const { Panel } = Collapse;

const OrdersDetails = () => {
  const role = Number(localStorage.getItem("role"));
  const { id } = useParams();

  var Layout = "";

  const [order, setOrder] = useState([]);
  const [orderLogs, setOrderLogs] = useState([])
  const [suppliers, setSuppliers] = useState([])

  let token = localStorage.getItem("token");

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (role == 1 || role == 3 || role == 5 || role == 2 || role == 10) {
    Layout = CPLayout;
  }
  
  useEffect(() => {
    const fetchOrderAndSuppliers = async () => {
      try {
        const orderResponse = await MarketPlaceService.getOrderById(id);
        const orderData = orderResponse.data;
        setOrder(orderData);
        
        const orderLogsResponse = await MarketPlaceService.getLogsByOrder(id);
        const orderLogsData = orderLogsResponse.data;
        setOrderLogs(orderLogsData);
  
        const uniqueSuppliers = [];
        const suppliersSet = new Set(); // Usaremos un conjunto para evitar duplicados.
  
        for (const product of orderData.products) {
          if (!suppliersSet.has(product.supplier_id)) {
            const supplierResponse = await UserService.GetUserById({ id: product.supplier_id });
            uniqueSuppliers.push(supplierResponse.data);
            suppliersSet.add(product.supplier_id);
          }
        }
  
        setSuppliers(uniqueSuppliers);
      } catch (error) {
        console.error("Error fetching order or suppliers:", error);
      }
    };
  
    fetchOrderAndSuppliers();
  
    return () => {
      setOrderLogs([])
      setOrder([]);
      setSuppliers([]);
    };
  }, [id]);
  

  return <>
  <Layout>
    <Row gutter={[16, 16]}>
      {/* Left Column */}
      <Col xs={24} md={16}>

        {/* Title and Status */}
        <Card style={{ marginBottom: "16px", backgroundColor: "#fff" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={4}> Referencia: {order.id} </Title>
            </Col>
            <Col>
              {role == 1 ? 
              <Button style={{marginRight: '16px'}}>Aceptar Orden</Button>
              :<></>}
              <Button danger>Cancelar orden</Button>
            </Col>
          </Row>
          <Text>{order.updated_at} - </Text>{" "}
          {/* TODO: Definir con Jorge */}
          <Tag color="gold">{order.status}</Tag>
          <br />
          <Text>Tu compra se encuentra en proceso</Text>
        </Card>


        {/* Service Details */}
        <Card title="Detalle de la compra" style={{ marginTop: 16, backgroundColor: "#F4F6F6", marginBottom: "16px" }} >
          <List>
            <List.Item>
              <Collapse bordered={false} style={{ width: "100%", backgroundColor: "#F4F6F6" }} >
                <Panel  header="Productos en la orden" key="1" style={{ width: "100%", padding: 0 }} >
                  <List dataSource={order.products} renderItem={(product) => (
                    <List.Item key={product.id} style={{ padding: "8px 0", display: "flex", alignItems: "center" }} >
                      <Avatar src={product.imgs[0]} alt={product.name} size={64} style={{ marginRight: "16px", borderRadius: '8px' }} />
                        <div style={{ flex: 1 }}>
                          <Text strong>{product.name}</Text>
                          <br />
                          <Text>Cantidad: {product.quantity} u.</Text>
                          <br />
                          <Text>Proveedor: {suppliers.find(s => s.id === product.supplier_id)?.name || 'No disponible'}</Text>
                        </div>
                        <Text strong style={{ marginLeft: "auto" }}> {product.price} $ {order.change_type}</Text>
                      </List.Item>
                    )}
                  />
                </Panel>
              </Collapse>
            </List.Item>
            <List.Item>
              <Text>Envío</Text>
              <Text>Gratis</Text>
            </List.Item>
            <List.Item>
              <Text strong> Total </Text>
              <Text strong> {order.price} $ {order.change_type} </Text>
            </List.Item>
            <List.Item>
              <Row style={{ marginTop: "16px" }} gutter={[16, 16]}>
                <Col span={12}>
                  <Text strong style={{ display: "block", marginBottom: "8px" }}>Fecha de entrega esperada:</Text>
                  <Text>30/10/2024</Text>
                </Col>
                <Col span={12}>
                  <Text strong style={{ display: "block", marginBottom: "8px" }}>Fecha de entrega:</Text>
                  <Text>Por definir</Text>
                </Col>
              </Row>
            </List.Item>
          </List>
        </Card>

      </Col>

        {/* Right Column */}
        <Col xs={24} md={8}>
        {/* Chat Section */}
        <Card style={{backgroundColor: "#fff", borderBottomLeftRadius:0 ,borderBottomRightRadius:0 }}>
            <Title level={5}>Chats</Title>
          </Card>
          <Card style={{ marginBottom: "16px", backgroundColor: "#fff", borderTopLeftRadius:0 ,borderTopRightRadius:0  }}>
            <Text>
             No data
            </Text>
          </Card>
          {/* Order Tracking */}
          <Card style={{backgroundColor: "#F4F6F6", borderBottomLeftRadius:0 ,borderBottomRightRadius:0 }}>
            <Title level={5}>Seguimiento de la orden</Title>
          </Card>
          <Card style={{ marginBottom: "16px", backgroundColor: "#fff", borderTopLeftRadius:0 ,borderTopRightRadius:0  }}>
            {orderLogs.map((log, index) => (
              <p key={index}> {log.updatedAt} - {log.status} </p>
            ))}
          </Card>
        </Col>
      </Row>
  </Layout></>
};

export default OrdersDetails;
