import React from 'react';
import { Row, Col, Card } from 'antd';
import { LineChart, Line, PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import LayoutPage from '../../../layouts/LayoutPage';
import SupplierLayout from '../../../layouts/SupplierLayout';
import CPLayout from '../../../layouts/ControlPanelLayout';
import { useNavigate } from 'react-router-dom';

const data = [
  { name: 'Jan', sales: 4000 },
  { name: 'Feb', sales: 3000 },
  { name: 'Mar', sales: 5000 },
  { name: 'Apr', sales: 2000 },
  { name: 'May', sales: 4500 },
];

const pieData = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const SupplierAnalytics = () => {
  let history = useNavigate();
  let token = localStorage.getItem('token');
  let role = localStorage.getItem('role');
  var Layout = "";
    if (role == 4) {
        Layout = LayoutPage;
      } else if (role == 6) {
        Layout = SupplierLayout;
      } else if (
        role == 1 ||
        role == 3 ||
        role == 5 ||
        role == 2 ||
        role == 10
      ) {
        Layout = CPLayout;
    }

  return (
    <Layout>
    <div style={{ padding: '24px' }}>
      <h1 style={{color: 'black'}}>Analíticas</h1>

      <Row gutter={16}>
        <Col span={12}>
          <Card title="Graph 1" bordered={false}>
            <LineChart width={400} height={300} data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="sales" stroke="#8884d8" />
            </LineChart>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Graph 2" bordered={false}>
            <PieChart width={400} height={300}>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                label
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '16px' }}>
        <Col span={24}>
          <Card title="Graph3" bordered={false}>
            <BarChart width={800} height={300} data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="sales" fill="#82ca9d" />
            </BarChart>
          </Card>
        </Col>
      </Row>
    </div>
    </Layout>
  );
};

export default SupplierAnalytics;
