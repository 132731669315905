import React from 'react';
import {useParams} from "react-router-dom";
import { Row, Col, Typography, Breadcrumb} from 'antd';
import LayoutPage from '../../layouts/LayoutPage';
import SupplierLayout from '../../layouts/SupplierLayout';
import CPLayout from '../../layouts/ControlPanelLayout';
import './styles.scss'; // Archivo CSS para los estilos
import { useNavigate } from 'react-router-dom';
import { CategoriesData } from '../../data/categories/categoriesData';
import AppBarMarketPlace from './global_components/AppBar';

const { Title } = Typography;

export default function Categories(props) {
  let history = useNavigate();
  const { category } = useParams();
  let token = localStorage.getItem('token');
  let role = localStorage.getItem('role');
  var Layout = "";
  
  const selectCategory = () => {
    const foundObject = categoriesData.find(obj => obj.category === formattedCategory);
    return foundObject ? foundObject.subCategories : null;
  };

  const formatCategoryName = (categoryName) => {
    return categoryName
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const categoriesData = CategoriesData;
  const formattedCategory = formatCategoryName(category);
  const selectedCategory = selectCategory();

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (
    role == 1 ||
    role == 3 ||
    role == 5 ||
    role == 2 ||
    role == 10
  ) {
    Layout = CPLayout;
  }

  const handleClick = (productName) => {
    const formattedSub = productName.toLowerCase().replace(/\s+/g, '-');
    history(`/marketplace/${formattedCategory}/${formattedSub}`);
  };  

  const handleBreadcrumbClick = (link) => {
    history(link);
  };

  return (
    <Layout>
      <AppBarMarketPlace/>
      <div style={{marginBotton:'40px'}}>
      <Breadcrumb style={{marginBottom:'20px'}}>
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick('/marketplace')}>
          Inicio
        </Breadcrumb.Item>
        <Breadcrumb.Item className='breadcrumb-item'>{formattedCategory}</Breadcrumb.Item>
      </Breadcrumb>
    </div>

      <Typography style={{fontWeight: 'bold', fontSize:'28px'}}>{formattedCategory}</Typography>
      <Row gutter={[16, 16]}>
  {selectedCategory.map((category, index) => (
    <Col
      key={index}
      xs={24}
      sm={12}
      md={6}
      className="category-col"
      onClick={() => {
        handleClick(category.name);
      }}
    >
      <div className="category-content">
        <div className="image-container">
          <img
            src={category.img}
            alt={category.name}
            className="category-image"
          />
        </div>
        <div className="category-footer">
          <Title level={4} className="category-title">
            {category.name}
          </Title>
          {category.description && <p className="category-description">{category.description}</p>}
        </div>
      </div>
    </Col>
  ))}
</Row>


    </Layout>
  );
}
