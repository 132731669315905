import React from 'react';
import { Typography, Breadcrumb } from 'antd';
import LayoutPage from '../../../layouts/LayoutPage';
import SupplierLayout from '../../../layouts/SupplierLayout';
import CPLayout from '../../../layouts/ControlPanelLayout';
import '../styles.scss'; // Archivo CSS para los estilos
import { useNavigate } from 'react-router-dom';
import SupplierList from './SuppliersList';
import AppBarMarketPlace from '../global_components/AppBar';

export default function AllSuppliers() {
  let history = useNavigate();
  let role = localStorage.getItem('role');
  var Layout = "";

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (role == 1 || role == 3 || role == 5 || role == 2 || role == 10) {
    Layout = CPLayout;
  }

  const handleBreadcrumbClick = (link) => {
    history(link);
  };

  return (
    <Layout>
      <AppBarMarketPlace />
      <div style={{ marginBottom: '40px' }}>
        <Breadcrumb items={[
          { title: ( <span className='breadcrumb-item' onClick={() => handleBreadcrumbClick('/marketplace')}> Inicio </span> ), },
          { title: <span className='breadcrumb-item'>Proveedores</span>, },
        ]}/>
      </div>
      <Typography style={{ fontWeight: 'bold', fontSize: '28px', marginBottom: '20px' }}>
        Todos los proveedores
      </Typography>
      <SupplierList />
    </Layout>
  );
}
