import React, { useState } from 'react';
import { Card, Button} from 'antd';
import { ShoppingOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import './styles.scss';

const ProductGallery = ({products, handleClick}) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  /*const scrollRight = () => {
    setScrollPosition((prev) => prev + 250);
  };

  const scrollLeft = () => {
    setScrollPosition((prev) => prev - 250);
  };*/

  return (
    <div className="product-gallery">
      <h2>Nuestros productos</h2>
      <div className="gallery-container">
        {/*
        <Button className="scroll-button left" onClick={scrollLeft}>←</Button>
        */}
        
        <div className="product-container" style={{ transform: `translateX(-${scrollPosition}px)` }}>
          {products.map((product) => (
            <div key={product.id} className="product-card">
              <Card
                hoverable
                cover={<img alt={product.name} src={product.images[0]} />}
                className="card-content"
                onClick={() => handleClick(product)}
              >
                <Card.Meta title={product.name}/>
                <div className="card-footer">
                  <p>${product.price.toLocaleString()}</p>
                </div>
              </Card>
            </div>
          ))}
          <div key={10} className="product-card">
              <Card
                hoverable
                cover={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                    backgroundColor: "#f0f2f5",
                  }}
                >
                  <ShoppingOutlined style={{ fontSize: "70px", color: "#1890ff" }} />
                </div>}
                className="card-content"
                onClick={() => handleClick('view-all')}
              >
              <Card.Meta title={'Ver Todo'}/>
                <div className="card-footer">
                  <p>...</p>
                </div>
              </Card>
            </div>
        </div>
        {/*
        <Button className="scroll-button right" onClick={scrollRight}>→</Button>
        */}
        
      </div>
    </div>
  );
};

export default ProductGallery;
