import request from "../services/HTTPClient";
import { requestMethods, API } from "../constants";

// Products ----------------------------------------------
function newProduct(body) {
  return request({
    url: API.MARKETPLACE.PRODUCTS.NEW,
    method: requestMethods.POST,
    data: body
  })
}

function updateProduct(body, id) {
  return request({
    url: API.MARKETPLACE.PRODUCTS.UPDATE.concat(id),
    method: requestMethods.PUT,
    data: body
  })
}

function deleteProduct(product_id) {
  return request({
    url: API.MARKETPLACE.PRODUCTS.DELETE.concat(product_id),
    method: requestMethods.DELETE,
  });
}

function getAllProducts() {
  return request({
    url: API.MARKETPLACE.PRODUCTS.GET_ALL,
    method: requestMethods.GET,
  });
}

function getProductById(id) {
  return request({
    url: API.MARKETPLACE.PRODUCTS.GET_BY_ID.concat(id),
    method: requestMethods.GET,
  });
}

function getProductsByCategory(cat, sub_cat) {
  return request({
    url: API.MARKETPLACE.PRODUCTS.GET_BY_CATEGORY.concat(cat + '/subcategory/' + sub_cat),
    method: requestMethods.GET,
  });
}

function getProductsBySupplier(id) {
  return request({
    url: API.MARKETPLACE.PRODUCTS.GET_BY_SUPPLIER.concat(id),
    method: requestMethods.GET,
  });
}

// Orders ------------------------------------------------
function newOrder(body) {
  return request({
    url: API.MARKETPLACE.ORDERS.NEW,
    method: requestMethods.POST,
    data: body
  })
}

function updateOrder(body, id) {
  return request({
    url: API.MARKETPLACE.ORDERS.UPDATE.concat(id),
    method: requestMethods.PUT,
    data: body
  })
}

function deleteOrder( id) {
  return request({
    url: API.MARKETPLACE.ORDERS.DELETE.concat(id),
    method: requestMethods.DELETE,
  })
}

function getOrderById(id) {
  return request({
    url: API.MARKETPLACE.ORDERS.GET_BY_ID.concat(id),
    method: requestMethods.GET,
  });
}

function getOrderByClient(id) {
  return request({
    url: API.MARKETPLACE.ORDERS.GET_BY_CLIENT.concat(id),
    method: requestMethods.GET,
  });
}

function getOrderBySupplier(id) {
  return request({
    url: API.MARKETPLACE.ORDERS.GET_BY_SUPPLIER.concat(id),
    method: requestMethods.GET,
  });
}

function getAllOrders() {
  return request({
    url: API.MARKETPLACE.ORDERS.GET_ALL,
    method: requestMethods.GET,
  });
}

// Cart ---------------------------------------------------
function addProductToCart(body) {
  return request({
    url: API.MARKETPLACE.CART.ADD_PRODUCT,
    method: requestMethods.POST,
    data: body
  })
}

function updateCart(body, id) {
  return request({
    url: API.MARKETPLACE.CART.UPDATE.concat(id),
    method: requestMethods.PUT,
    data: body
  })
}


function deleteCart(id) {
  return request({
    url: API.MARKETPLACE.CART.DELETE.concat(id),
    method: requestMethods.DELETE,
  })
}

function deleteCartProduct(user_id, product_id) {
  return request({
    url: API.MARKETPLACE.CART.DELETE_PRODUCT.concat(user_id + '/' + product_id),
    method: requestMethods.DELETE,
  })
}

function getCartById(id) {
  return request({
    url: API.MARKETPLACE.CART.GET_BY_ID.concat(id),
    method: requestMethods.GET,
  })
}

function getCartByClient(id) {
  return request({
    url: API.MARKETPLACE.CART.GET_BY_CLIENT.concat(id),
    method: requestMethods.GET,
  })
}

function getAllCarts() {
  return request({
    url: API.MARKETPLACE.CART.GET_ALL,
    method: requestMethods.GET,
  })
}

// Logs --------------------------------------------------
function getLogsByOrder(id) {
  return request({
    url: API.MARKETPLACE.LOGS.GET_BY_ORDER.concat(id),
    method: requestMethods.GET,
  })
}

// Vendor -----------------------------------------------
function addVendor(body) {
  return request({
    url: API.MARKETPLACE.VENDOR.NEW,
    method: requestMethods.POST,
    data: body
  })
}

function updateVendor(body, id) {
  return request({
    url: API.MARKETPLACE.VENDOR.UPDATE.concat(id),
    method: requestMethods.PUT,
    data: body
  })
}

function archiveVendor(id) {
  return request({
    url: API.MARKETPLACE.VENDOR.ARCHIVE.concat(id),
    method: requestMethods.PUT,
  })
}

function unarchiveVendor(id) {
  return request({
    url: API.MARKETPLACE.VENDOR.UNARCHIVE.concat(id),
    method: requestMethods.PUT,
  })
}

function deleteVendor(id) {
  return request({
    url: API.MARKETPLACE.VENDOR.DELETE.concat(id),
    method: requestMethods.DELETE,
  })
}

function getAllVendors() {
  return request({
    url: API.MARKETPLACE.VENDOR.GET_ALL,
    method: requestMethods.GET,
  })
}

function getAllVendorsByUser(id) {
  return request({
    url: API.MARKETPLACE.VENDOR.GET_ALL_BY_USER.concat(id),
    method: requestMethods.GET,
  })
}

function getVendorByUser(id) {
  return request({
    url: API.MARKETPLACE.VENDOR.GET_BY_USER.concat(id),
    method: requestMethods.GET,
  })
}

const MarketPlaceService = {
  newProduct,
  updateProduct,
  deleteProduct,
  getAllProducts,
  getProductById,
  getProductsByCategory,
  getProductsBySupplier,

  // Orders
  newOrder,
  updateOrder,
  deleteOrder,
  getOrderById,
  getOrderByClient,
  getOrderBySupplier,
  getAllOrders,

  // Cart
  addProductToCart,
  updateCart,
  deleteCart,
  deleteCartProduct,
  getAllCarts,
  getCartByClient,
  getCartById,

  //Logs
  getLogsByOrder,

  // Vendor
  addVendor,
  updateVendor,
  archiveVendor,
  unarchiveVendor,
  deleteVendor,
  getAllVendors,
  getAllVendorsByUser,
  getVendorByUser
};

export default MarketPlaceService;
