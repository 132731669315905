import React, { useEffect, useState } from "react";
import { List, Button, Card, Tag, Row, Col, message, Input } from "antd";
import { AppstoreOutlined, BarsOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import MarketPlaceService from "../../../utils/api/marketplace";

const { Search } = Input;

const ProductList = ({ product, catalog, category, qty, setQty}) => {
  const user = JSON.parse(localStorage.getItem('user'));

  let history = useNavigate();
  const [gridColumns, setGridColumns] = useState(1);

  const handleCardClick = (product) => {
    history(`/marketplace/${product.category}/${product.sub_category}/${product.id}?name=${encodeURIComponent(product.name)}`);
  };

  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [loadProducts, setLoadProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(products);

  useEffect(() => {
    setLoadProducts(true);
      MarketPlaceService.getProductsBySupplier(catalog).then((resp) => {
        setProducts(resp.data.products);
        setFilteredProducts(resp.data.products)
        setLoadProducts(false);
      }).catch( error => {
        setProducts([]);
        setFilteredProducts([])
        setLoadProducts(false);
      });
  }, []);
  
  const handleSearch = (value) => {
    const filtered = products.filter((sup) =>
      sup.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(filtered);
  };
  
  return <>
    <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
      <div style={{ flex: 3 }}>
          <Row>
          <Col xs={12} md={6}>
            <Search
              placeholder="Buscar por nombre..."
              enterButton={<SearchOutlined/>}
              onSearch={handleSearch}
              style={{ marginBottom: "20px"}}
            />
          </Col>
          <Col xs={0} md={12}/>
          <Col xs={12} md={6} style={{ display: "flex", justifyContent: "flex-end"}}>
          <Button
              onClick={() => setGridColumns(1)}
              icon={<BarsOutlined />}
              style={{ marginRight: "10px", marginLeft:'10px' }}
            >
              Lista
            </Button>
            <Button onClick={() => setGridColumns(2)} icon={<AppstoreOutlined />}>
              Galería
            </Button>
          </Col>
        </Row>

        <List
          grid={{ gutter: 12, column: gridColumns }}
          dataSource={filteredProducts}
          loading={loadProducts}
          renderItem={(item) => (
            <List.Item style={{ width: "100%" }}>
              <Card
                bordered={true}
                style={{
                  display: "block",
                  width: "100%",
                  cursor: "pointer",
                  transition: "0.3s",
                  hover: { boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)" },
                }}
                onClick={() => handleCardClick(item)}
              >
                <Row gutter={[24, 24]} style={{ width: "100%" }}>
                  <Col xs={24} md={6} style={{ textAlign: "left" }}>
                    <img
                      src={item.images[0]}
                      alt={item.name}
                      style={{ width: 100, height: 100 }}
                    />
                    {gridColumns === 1 ? null : (
                      <>
                        <Tag color="blue">{item.category}</Tag>
                        <Tag color="green">{item.sub_category}</Tag>
                      </>
                    )}
                  </Col>
                  <Col xs={24} md={12} style={{ textAlign: "left", alignContent: "flex-start" }}>
                    <div>
                      <h3>{item.name}</h3>
                      <p>{item.description}</p>
                      {gridColumns === 2 ? null : (
                        <>
                          <Tag color="blue">{item.category}</Tag>
                          <Tag color="green">{item.sub_category}</Tag>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col xs={24} md={6} style={{ textAlign: "right" }}>
                    <div>
                      <h2 style={{ marginBottom: 8 }}>
                        ${item.price}
                      </h2>
                      <p style={{ color: "green" }}>Sin costo de envío</p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      </div>
    </div>
    </>
};

export default ProductList;
