import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, InputNumber, Upload, Button, Row, Col, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const NewProduct = ({ open, setOpen, onSubmit, vendor }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { Option } = Select;

  const handleOk = () => {
    setLoading(true);
    form.validateFields().then((values) => {
      // Enviar los valores del formulario al método onSubmit
      onSubmit(values);
      setLoading(false);
      form.resetFields();
      setOpen(false);
    }).catch((info) => {
      console.error('Validation Failed:', info);
      setLoading(false);
    });
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  

  // Procesar imágenes para generar un thumbUrl
  const handlePreview = async (file) => {
    if (!file.thumbUrl && file.originFileObj) {
      file.thumbUrl = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(file.originFileObj);
      });
    }
    return file.thumbUrl;
  };

  useEffect(() => {
    console.log(vendor)
  
    return () => {
      
    }
  }, [])
  

  return (
    <Modal
      title="Añadir Nuevo Producto"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Añadir"
      cancelText="Cancelar"
    >
      <Form form={form} layout="vertical" name="new_product_form">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Nombre"
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <Input placeholder="Nombre del producto" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="sku"
              label="SKU"
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <Input placeholder="SKU" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="price"
              label="Precio"
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <InputNumber style={{ width: '100%' }} placeholder="Precio en MXN" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="stock"
              label="Stock"
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <InputNumber style={{ width: '100%' }} placeholder="Cantidad disponible" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="category"
              label="Categoría"
              rules={[{ required: true, message: 'Por favor selecciona una categoría.' }]}
            >
              <Select placeholder="Selecciona una categoría">
                <Option value={vendor.vendor?.category}>{vendor.vendor?.category}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="sub_category"
              label="Subcategoría"
              rules={[{ required: true, message: 'Por favor selecciona una subcategoría.' }]}
            >
              <Select placeholder="Selecciona una subcategoría">
                {vendor.vendor?.sub_categories.map((aux, i) => (
                  <Option value={aux}>{aux}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="description"
          label="Descripción"
          rules={[{ required: true, message: 'Este campo es requerido' }]}
        >
          <Input.TextArea rows={4} placeholder="Descripción del producto" />
        </Form.Item>
        <Form.Item
          name="images"
          label="Imágenes"
          valuePropName="fileList"
          getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
        >
          <Upload
            name="images"
            listType="picture"
            multiple
            beforeUpload={() => false} // Evitar la subida automática
            onPreview={handlePreview}
          >
            <Button icon={<UploadOutlined />}>Subir Imágenes</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewProduct;
